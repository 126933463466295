#content{
  text-align: center;
  text-shadow: 0px 4px 3px rgba(0,0,0,0.4);
  text-shadow: 0px 8px 13px rgba(0,0,0,0.1);
  text-shadow: 0px 18px 23px rgba(0,0,0,0.1);
}
html{
  height: 100%
}
body{
  background: white;
  background-size: cover;
  background-position: center;
  color: white;
}
h1{
  font-style: normal;
  font: arial;
  font-weight: 700;
  font-size: 5em;
  color: black;
}
hr{
  width: 800px;
}
h3{
  color: black;
}
#text{
  color: black;
}
#map-dropdown{
  color: black;
  font-size: medium;
  height: 74vh;
}
pre{
  background-color: white;
}
.code{
  background-color: #d9d9d9;
}
img{
  width: 45%;
  height: 45%;
  float: left;
  margin: 1.66%;
}
#button{
  color: black;
  background-color: white;
  font-size: medium;
  width: 90px;
  height: 8vh;
}
#button_container{
  margin-top: 1vh;
  text-align: center;
}
.btn-default{
  color: black;
  background: #8ecbe1;
}
.map{
  height:80vh; 
  width: 100%;
}
select option:disabled {
  color: rgba(0, 0, 0, 0.15);
}
.img-fluid {
  width: 100%;
}
